import React, { lazy, Suspense } from 'react';
import css from './App.module.scss';
import { BrowserRouter as Router, Link, Switch, Route } from 'react-router-dom';
const ListView = lazy(() => import('./views/ListView/ListView'));
const SingleRestaurantView = lazy(() => import('./views/SingleRestaurantView/SingleRestaurantView'));

const App: React.FC = () => {
  return (
    <div className={css.App}>
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <nav>
            <ul>
              <li>
                <Link to="/">List view</Link>
              </li>
              <li>
                <Link to="/restaurants/1">Single view</Link>
              </li>
            </ul>
          </nav>
          <Switch>
            <Route path="/restaurants/:slug">
              <SingleRestaurantView />
            </Route>
            <Route path="/">
              <ListView />
            </Route>
          </Switch>
        </Suspense>
      </Router>
    </div>
  );
};

export default App;
